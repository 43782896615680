import React, { Component } from "react";
import { Card, CardHeader, ListGroup, ListGroupItem, Row, Col, Form, FormGroup, FormInput, Button, Tooltip } from "shards-react";

import commonFunction from "../../utils/commonFunctions";
import axios from "axios";
import config from "../../utils/config";
import { showAlert } from "../../services/alert.services";
import { Store } from "../../flux";

class TransectionHistory extends Component {
  constructor(props) {
    super(props);
    this.is_admin = Store.getAdminStatus();
    this.title = "Account Details";
    this.state = {
      userData: {},
      change_password: {
        old_password: "",
        password: "",
      },
      top: false,
    };
  }

  getProfileInfo = () => {
    const token = localStorage.getItem("token");
    const header = commonFunction.getDefaultHeader(token);
    axios
      .get(config.api_server + "/customer/profile", header)
      .then((resp) => {
        console.log(resp.data.data);
        if (resp.data) {
          this.setState({ userData: resp.data.data });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  componentDidMount() {
    this.getProfileInfo();
  }

  updateProfile = (e) => {
    const header = commonFunction.getDefaultHeader();
    axios
      .put(config.api_server + "/customer/update", this.state.userData, header)
      .then((resp) => {
        console.log(resp.data.data);
        showAlert(resp.data.message, "s");
      })
      .catch((e) => {
        console.log(e);
      });
    e.preventDefault();
  };

  changePassword = (e) => {
    const header = commonFunction.getDefaultHeader();
    let api = config.api_server + "/customer/change-password";

    if (this.is_admin) {
      api = config.api_server + "/admin/change-password";
    }
    axios
      .put(api, this.state.change_password, header)
      .then((resp) => {
        console.log(resp.data.data);
        showAlert(resp.data.message, "s");
        let change_password = this.state.change_password;
        change_password.old_password = "";
        change_password.password = "";
        this.setState({ change_password });
      })
      .catch((error) => {
        if (error.response && error.response.data.message) {
          showAlert(error.response.data.message, "e");
        }
      });
    e.preventDefault();
  };

  render() {
    return (
      <div lg="6">
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <h6 className="m-0">{"ADDITIONAL BONUS TECHNICIAN EARNS (REVIEWS, BOUNTYS, TIPS)"}</h6>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col></Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </div>
    );
  }
}

export default TransectionHistory;
