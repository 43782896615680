import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col } from "shards-react";

import config from "../utils/config";
import commonFunction from "../utils/commonFunctions";

import PageTitle from "../components/common/PageTitle";
import FormHistory from "../components/UserInfo/FormHistory";
import UserDetails from "../components/UserInfo/UserDetails";
import TransectHistory from "../components/UserInfo/TransectionHistory";
import UserSubscription from "../components/UserInfo/UserSubscriptions";
import LoaderComponent from "../components/Loader/Loader";

const UserInfoView = props => {
  let userId = props.match.params.userId || "";
  console.log("userId", userId);

  const [user, setUser] = useState(0);
  const [Loader, setLoader] = useState("true");

  const setUserDetail = user => {
    setUser(user);
  };

  const fetchUserDetail = userId => {
    const header = commonFunction.getDefaultHeader(
      "eyJhbGciOiJSUzUxMiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7Il9pZCI6IjVmZTQ3MDE1YTFiZDVlNmU1YjAwMmExNSIsInByb2ZpbGVfaW1hZ2UiOiJodHRwOi8vY29uc2Vuc3VhbGNvbnNlbnRmb3Jtcy5jb206NDIwMC9wbGFjZS1ob2xkZXItaW1hZ2VzL3VzZXItcGxhY2Vob2xkZXIucG5nIiwicm9sZSI6ImFkbWluIiwic3RhdHVzIjoiYWN0aXZlIiwib3RwIjoiIiwiZGV2aWNlX3R5cGUiOiJBTkRST0lEIiwiZGV2aWNlX3Rva2VuIjoiIiwic3RyaXBlX2N1c3RvbWVyX2lkIjoiIiwiZmlyc3RfbmFtZSI6IkFrc2hheSIsImxhc3RfbmFtZSI6IkFoaXIiLCJlbWFpbCI6ImFkbWluQGdtYWlsLmNvbSIsInBhc3N3b3JkIjoiY2RhZWM3YjExMDlkMmZjYzQ1YTg4Nzg1NGRkMzFlNjc2YWMzNDkwYmVmYjQzYzZjMGMiLCJkYXRlX29mX2JpcnRoIjoiMTk5Ny0wOC0zMFQwMDowMDowMC4wMDBaIiwibW9iaWxlX251bWJlciI6OTg3NjU0MzIxMDEwLCJhZGRyZXNzIjp7InN0cmVldF9hZGRyZXNzIjoiU2hhcGF0aCBoZXhhIiwiY2l0eSI6IkFuY2hvcmFnZSIsInppcF9jb2RlIjoiMzYwMDYwIiwic3RhdGUiOiJBbGFza2EiLCJzdGF0ZV9pZCI6IjM5MjAiLCJjaXR5X2lkIjoiNDI2NzAifSwiY3JlYXRlZEF0IjoiMjAyMC0xMi0yNFQxMDo0MDoyMS42NDVaIiwidXBkYXRlZEF0IjoiMjAyMC0xMi0yNFQxMDo0MDoyMS42NDVaIiwiX192IjowfSwiaWF0IjoxNjA5MzA2MDk2LCJhdWQiOiJ1cm46Zm9vIiwiaXNzIjoiaUJsYXppbmcifQ.a_b0pSufIIP2RJ3HeYyjTBa3chSZX-mlNZW26B3J7m0aw5UhL2W6U5WXfk22DxRax17nuZyLC7EpERgvGkMsdcIJTxPRLvdyS7E3LSN2yTIrUdBEU8va4SFdCorFGhP34tDqdhCXC5X--8dOBAGy66afzwOzVmv8kjQsH1oYKez4AVT6ySb3Rnjru8QubHD4E37LZdoO3hHCqAFxOX5fmJMcuYxPdlpKZOBchc8TgqDTtgABDtWmqq5Gt_yPmvIX6z8T2eVLk5Os91Kah3mKOvlkkoeOxC8VUMUJYFMo6Aw4iD5dY1fzpsP8tniGewFTIkolpw8emcSoQKIox5Xojta1vQ1Q5Q-hi3R2FIxnuzicQ228avUJXl_3i4hxkeJBHz_JvKKpf86tEHrLxsNKtcQAW-_QYxLEnfG7aSp-YWD-xljyxQmiFoQ-keB_VSO2MEYsmxB-6_hBiLs3bdxUmrj3QiPiUXVmFNBrogZr1SMO1DWKg7EtEVARgpAF1BLQoXu0qip47eTUgfBjfw1Q-w52atIpuXX4F4iv0hbP16SZNLzhN8hx5YKYButEESoxO8HaTrDPIhke7mAMjGcTCQ8EGJx3Ip-Yl8mfTHJoDwFOLSa1Auxhx-QJmAmtwxDvItQ3kBYBF7DWptUFRJKITHl9haoBaof-eEUxFTlKjC0"
    );

    axios
      .get(
        "http://consensualconsentforms.com:4200/api/admin/user-details/5ffe3a74dca8272b0a3089bb",
        header
      )
      .then(resp => {
        if (resp.data) {
          console.log("Data ==>", resp.data.data);
          setUserDetail(resp.data.data);
          setLoader("false");
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    console.log("41");
    fetchUserDetail(userId);
  }, [Loader]);

  console.log("Loader", Loader);
  if (Loader === "true") return <LoaderComponent />;

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Employee Profile"
          subtitle="Overview of"
          md="12"
          className="ml-sm-auto mr-sm-auto"
        />
      </Row>
      <Row>
        <Col lg="3">
          <UserDetails userInfo={user.user_info} />
        </Col>
        <Col lg="9">
          <FormHistory userForms={user.user_forms} />
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <TransectHistory />
        </Col>
        <Col lg="6">
          <UserSubscription />
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <TransectHistory />
        </Col>
        <Col lg="6">
          <UserSubscription />
        </Col>
      </Row>
    </Container>
  );
};

export default UserInfoView;
