import React from "react";
import faker from "faker";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import { Button } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "shards-react";
import { Form, FormGroup, FormInput, FormSelect } from "shards-react";
import PageTitle from "../common/PageTitle";
import SmallStats from "../common/SmallStats";
import commonFunction from "../../utils/commonFunctions";
import config from "../../utils/config";
import axios from "axios";

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: [],
      error: null,
      isLoaded: false,
      isModelShow: false,
    };
  }

  fetchForm = () => {
    console.log("fetchFormURL -> ", config.api_server + "/form-question/list");
    const header = commonFunction.getDefaultHeader("");
    axios
      .get(config.api_server + "/form-question/list", header)
      .then((resp) => {
        if (resp.data) {
          console.log("response data", resp.data.data);
          this.setState({ form: resp.data.data, isLoaded: true });
        }
      })
      .catch((e) => {
        console.log(e);
      });
    this.setState({ isLoaded: true });
  };

  deleteQuestionService = (id) => {
    console.log(
      "Delete Q -> ",
      config.api_server + "/form-question/delete/" + id
    );

    const header = commonFunction.getDefaultHeader("");

    axios
      .get(config.api_server + "/form-question/delete/" + id, header)
      .then((resp) => {
        if (resp.data) {
          let form = this.state.form;
          form = form.filter((cus) => {
            return cus._id != id;
          });
          this.setState({ form });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  componentDidMount() {
    this.fetchForm();
  }

  hideModel = () => {
    this.setState({
      isModelShow: !this.state.isModelShow,
    });
  };

  handleDelete = (customerId) => {
    this.deleteQuestionService(customerId);
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    let form = event.target;
    console.log("form", form.elements);
    let field = {
      question: form.elements.question.value,
      type: form.elements.type.value,
    };
    const header = commonFunction.getDefaultHeader("");

    axios
      .post(config.api_server + "/form-question/add", field, header)
      .then((resp) => {
        if (resp.data) {
          /*let form = this.state.form;
        form.push(resp.data.data);
        this.setState({form})*/
          this.fetchForm();
        }
      })
      .catch((e) => {
        console.log(e);
      });
    this.hideModel();
  };

  render() {
    const { error, isLoaded } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      console.log(this.state.items);
      return (
        <>
          <Card small className="h-100">
            <CardHeader className="border-bottom">
              <Row style={{ textAlign: "right" }}>
                <div className={"col-md-12"}>
                  <Button
                    onClick={() => this.hideModel()}
                    className={"pull-right"}
                    variant="outline-primary"
                  >
                    Add New Field
                  </Button>{" "}
                </div>
              </Row>
            </CardHeader>
            <CardBody className="pt-0">
              <Row className="border-bottom py-2 bg-light">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Question</th>
                      <th scope="col">Type</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.form.length > 0
                      ? this.state.form.map((data) => {
                          return (
                            <tr key={data._id}>
                              <td>{data.question}</td>
                              <td>{data.type}</td>
                              <td>
                                <Button
                                  value={data._id}
                                  onClick={this.handleDelete.bind("", data._id)}
                                  variant="outline-danger"
                                >
                                  <i className="far fa-times-circle"></i>
                                </Button>
                              </td>
                            </tr>
                          );
                        })
                      : ""}
                  </tbody>
                </table>
              </Row>
            </CardBody>
          </Card>
          <Modal
            size={"lg"}
            open={this.state.isModelShow}
            toggle={this.hideModel}
          >
            <ModalHeader>Add New Field</ModalHeader>
            <ModalBody>
              <Form id={"customer-form"} onSubmit={this.handleSubmit}>
                <FormGroup className={"row"}>
                  <label className={"col-md-2"} htmlFor="question">
                    Question
                  </label>
                  <FormInput
                    className={"col-md-10"}
                    id="question"
                    placeholder="Question"
                    required
                  />
                </FormGroup>
                <FormGroup className={"row"}>
                  <label className={"col-md-2"} htmlFor="type">
                    Type
                  </label>
                  <FormSelect className={"col-md-4"} id="type" required>
                    <option value="input_value">Input Type</option>
                    <option>boolean</option>
                  </FormSelect>
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button form={"customer-form"} type={"submit"}>
                Save
              </Button>
              <Button className={"btn-secondary"} onClick={this.hideModel}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </>
      );
    }
  }
}

export default List;
