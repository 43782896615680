import React from "react";
import faker from "faker";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider, PaginationListStandalone, PaginationTotalStandalone, SizePerPageDropdownStandalone } from "react-bootstrap-table2-paginator";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

import { Button } from "react-bootstrap";
import { Card, CardBody, CardHeader, Row, Modal, ModalBody, ModalHeader, ModalFooter } from "shards-react";
import { Form, FormGroup, FormInput } from "shards-react";
import PageTitle from "../common/PageTitle";
import SmallStats from "../common/SmallStats";
import commonFunction from "../../utils/commonFunctions";
import config from "../../utils/config";
import axios from "axios";

class CustomerListTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      error: null,
      isLoaded: false,
      isModelShow: false,
      status: "",
    };
  }
  columns = [
    {
      dataField: "first_name ",
      text: "Name",
      formatter: (cellContent, row) => {
        return <p>{row.first_name + " " + row.last_name}</p>;
      },
    },
    {
      dataField: "mobile",
      text: "Mobile",
    },
    {
      dataField: "app_id",
      text: "Application",
      formatter: (cellContent, row) => {
        return <p>{row.app_id ? row.app_id.name : " "}</p>;
      },
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cellContent, row) => {
        return <p>{row.status}</p>;
      },
    },
    {
      dataField: "option",
      text: "Option",
      formatter: (cellContent, row) => {
        return (
          <BootstrapSwitchButton
            checked={row.status == "Active" ? true : false}
            onlabel="Active"
            offlabel="Deactivate"
            width={110}
            onstyle="outline-success"
            offstyle="outline-danger"
            onChange={(checked) => {
              console.log(row._id);
              console.log("Checked : ", checked);
              this.handleStatus(row._id, checked);
              this.setState({ status: checked });
            }}
          />
        );
      },
    },
  ];

  paginationOption = {
    custom: true,
    totalSize: 10,
  };

  fetchUsers = () => {
    console.log("fetchUer", config.api_server + "/admin/users-list/");
    const header = commonFunction.getDefaultHeader("", true);
    axios
      .post(config.api_server + "/admin/user-list", {}, header)
      .then((resp) => {
        if (resp.data) {
          console.log(resp.data.data);
          this.setState({ customers: resp.data.data, isLoaded: true });
        }
      })
      .catch((e) => {
        console.log(e);
      });
    this.setState({ isLoaded: true });
  };

  updateUserStatus = (user_id, status) => {
    console.log("updateUserStatus", config.api_server + "/admin/updateUserStatus/" + user_id);
    const header = commonFunction.getDefaultHeader("", true);
    axios
      .post(config.api_server + "/admin/updateUserStatus/" + user_id, { status: status ? "Active" : "Deactivate" }, header)
      .then((resp) => {
        if (resp.data) {
          console.log(resp.data.data);
          this.fetchUsers();
          //this.setState({ customers: resp.data.data, isLoaded: true });
        }
      })
      .catch((e) => {
        console.log(e);
      });
    this.setState({ isLoaded: true });
  };
  componentDidMount() {
    this.fetchUsers();
  }

  hideModel = () => {
    this.setState({
      isModelShow: !this.state.isModelShow,
    });
  };

  handleStatus = (user_id, status) => {
    this.updateUserStatus(user_id, status);
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    let form = event.target;
    let customer = {
      full_name: form.elements.full_name.value,
      app_name: form.elements.app_name.value,
      email: form.elements.email.value,
      mobile: form.elements.mobile.value,
      icabbi_api_url: form.elements.icabbi_api_url.value,
      icabbi_client_id: form.elements.icabbi_client_id.value,
      icabbi_secerate_key: form.elements.icabbi_secerate_key.value,
      password: form.elements.password.value,
      is_active: true,
      role: "customer",
      is_verified: true,
      is_deleted: false,
    };
    const header = commonFunction.getDefaultHeader("");

    axios
      .post(config.api_server + "/customer/create", customer, header)
      .then((resp) => {
        if (resp.data) {
          let customers = this.state.customers;
          customers.push(resp.data.data);
          this.setState({ customers });
        }
      })
      .catch((e) => {
        console.log(e);
      });
    this.hideModel();
  };

  render() {
    const { error, isLoaded } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      console.log(this.state.items);
      return (
        <>
          <Card small className="h-100">
            <CardHeader className="border-bottom">
              <Row style={{ textAlign: "right" }}>
                <div className={"col-md-12"}>{/*<Button
                      onClick={() => this.hideModel()}
                      className={"pull-right"}
                      variant="outline-primary"
                    >
                      Create Customer
                    </Button>{" "} */}</div>
              </Row>
            </CardHeader>
            <CardBody className="pt-0">
              <Row className="border-bottom py-2 bg-light">
                <BootstrapTable keyField="id" keyField={"_id"} data={this.state.customers} columns={this.columns} pagination={paginationFactory()} wrapperClasses="table-responsive" />
              </Row>
            </CardBody>
          </Card>
          <Modal size={"lg"} open={this.state.isModelShow} toggle={this.hideModel}>
            <ModalHeader>Customer Form</ModalHeader>
            <ModalBody>
              <Form id={"customer-form"} onSubmit={this.handleSubmit}>
                <FormGroup className={"row"}>
                  <label className={"col-md-2"} htmlFor="full_name">
                    Full Name
                  </label>
                  <FormInput className={"col-md-4"} id="full_name" placeholder="Name" required />
                  <label className={"col-md-2"} htmlFor="app_name">
                    App Name
                  </label>
                  <FormInput className={"col-md-4"} id="app_name" placeholder="App Name" required />
                </FormGroup>
                <FormGroup className={"row"}>
                  <label className={"col-md-2"} htmlFor="email">
                    Email
                  </label>
                  <FormInput className={"col-md-4"} type={"email"} id="email" placeholder="Email" required />
                  <label className={"col-md-2"} htmlFor="mobile">
                    Mobile
                  </label>
                  <FormInput className={"col-md-4"} type={"number"} id="mobile" placeholder="Mobile" required />
                </FormGroup>
                <FormGroup className={"row"}>
                  <label className={"col-md-2"} htmlFor="icabbi_api_url">
                    API Url
                  </label>
                  <FormInput className={"col-md-4"} id="icabbi_api_url" placeholder="ICABBI API Url" required />
                  <label className={"col-md-2"} htmlFor="icabbi_client_id">
                    Client Id
                  </label>
                  <FormInput className={"col-md-4"} id="icabbi_client_id" placeholder="ICABBI Client Id" required />
                </FormGroup>
                <FormGroup className={"row"}>
                  <label className={"col-md-2"} htmlFor="icabbi_secerate_key">
                    Secret Key
                  </label>
                  <FormInput className={"col-md-4"} id="icabbi_secerate_key" placeholder="ICABBI Secret Key" required />
                  <label className={"col-md-2"} htmlFor="password">
                    Password
                  </label>
                  <FormInput className={"col-md-4"} id="password" placeholder="Client Password" required />
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button form={"customer-form"} type={"submit"}>
                Save
              </Button>
              <Button className={"btn-secondary"} onClick={this.hideModel}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </>
      );
    }
  }
}

export default CustomerListTable;
