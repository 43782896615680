import axios from "axios";

import config from "../utils/config";
import commonFunction from "../utils/commonFunctions";

export const isLogin = async () => {};

export const login = async (data, is_admin = false) => {
  try {
    const header = commonFunction.getDefaultHeader("");
    let api = config.api_server + "/admin/login";
    console.log("API ==> ", api);
    console.log("Header", header);
    let response = await axios.post(
      api,
      data,
      header
    );
    return response;
  } catch (err) {
    console.log("LoginAPIError -> ", err);
    return err;
  }
};
