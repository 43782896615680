import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Route, Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider, PaginationListStandalone, PaginationTotalStandalone, SizePerPageDropdownStandalone } from "react-bootstrap-table2-paginator";

import { Card, CardHeader, CardBody, ListGroup, ListGroupItem, Row, Col, Form, FormGroup, FormInput, FormSelect, FormTextarea, Button } from "shards-react";

import commonFunction from "../../utils/commonFunctions";

const UserAccountDetails = (props) => {
  const [UserForms, setUserForms] = useState([]);

  let columns = [
    {
      dataField: "form_name",
      text: "Name",
    },
    {
      dataField: "payment_status",
      text: "Payment Status",
    },
    {
      dataField: "NDA",
      text: "false",
    },
    {
      dataField: "created_at",
      text: "Date",
      formatter: (column, row) => {
        return commonFunction.convertToDate(row.created_at);
      },
    },
    {
      dataField: "option",
      text: "Option",
      formatter: (cellContent, row) => {
        return (
          <div>
            <a href={row.pdf_file_url} target="_blank" download>
              <i className="fas fa-file-download"></i>
            </a>
            {/** 
             * 
             * <Button
              value={row._id}
              onClick={this.handleRequestStatus.bind("", row._id, "reject")}
              variant="outline-danger"
            >
              <i class="far fa-times-circle"></i>
            </Button>
             *  */}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setUserForms(props.userForms);
  });

  return (
    <Card small className="mb-4">
      <CardHeader className="border-bottom">
        <h6 className="m-0">WEEKLY HOURS AND PRODUCTION</h6>
      </CardHeader>
      <CardBody className="pt-0">
        <Row className="border-bottom py-2 bg-light">
          <BootstrapTable keyField="id" keyField={"_id"} data={UserForms} columns={columns} wrapperClasses="table-responsive" />
        </Row>
      </CardBody>
    </Card>
  );
};

UserAccountDetails.defaultProps = {
  title: "Form History",
};

export default UserAccountDetails;
