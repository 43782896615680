import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { AuthLayout, DefaultLayout, BlankLayout } from "./layouts";

// Route Views
import BlogOverview from "./views/BlogOverview";
import Errors from "./views/Errors";
import AdminLogin from "./views/AdminLogin";
import Users from "./views/Users";
import FormView from "./views/FormView";
import UserInfoView from "./views/UserInfoView";
import AdminProfile from "./views/AdminProfile";
import ApplicationView from "./views/ApplicationView";

export default [
  {
    path: "/",
    exact: true,
    layout: AuthLayout,
    component: () => <Redirect to="/login" />,
    //component: () => <Redirect to="/blog-overview" />,
  },
  {
    path: "/dashboard",
    exact: true,
    layout: DefaultLayout,
    component: BlogOverview,
  },
  {
    path: "/apps",
    exact: true,
    layout: DefaultLayout,
    component: ApplicationView,
  },
  {
    path: "/users",
    exact: true,
    layout: DefaultLayout,
    component: Users,
  },

  {
    path: "/users-detial/:userId",
    exact: true,
    layout: DefaultLayout,
    component: UserInfoView,
  },
  {
    path: "/form-questions",
    exact: true,
    layout: DefaultLayout,
    component: FormView,
  },
  {
    path: "/profile",
    exact: true,
    layout: DefaultLayout,
    component: AdminProfile,
  },
  {
    path: "/errors",
    exact: true,
    layout: DefaultLayout,
    component: Errors,
  },
  {
    path: "/login",
    layout: AuthLayout,
    component: AdminLogin,
    params: { is_admin: false },
  },
  {
    path: "/admin",
    layout: AuthLayout,
    component: AdminLogin,
    params: { is_admin: true },
  },
];
