import React, { Component } from "react";
import { Container, Row, Col, Image } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import { Store } from "../flux";
import "../assets/AuthLayout.css";
import Login from "../components/login/Login";

class AdminLogin extends Component {
  constructor(props) {
    super(props);
    console.log("Admin ==>", props);
    Store.setAdminStatus(props.match.url == "/admin" ? true : false);
    this.state = {
      is_admin: props.match.url == "/admin" ? true : false,
      logo: require("../images/frontlogo.png")
    };
  }

  componentDidMount() {
    this.setState({ redirect: null });
  }

  render() {
    return (
      <Container fluid className="centered main-content-container px-4 pb-4">
        {/* Page Header */}

        <Row noGutters className="page-header py-4">
          <PageTitle sm="1" className="page-title-center text-sm-left" />
        </Row>

        <Row>
          <Col lg="4" md="2"></Col>
          {/* Editor */}
          <Col lg="4" md="8">
            <Row noGutters className="page-header py-4">
              {/*<img
              src={this.state.logo}
              width="150"
              className="page-title-center text-sm-left logo-image"
            />*/}
              <h2 className="page-title-center text-sm-left ">Super-admin</h2>
            </Row>
            <Login is_admin={this.state.is_admin} />
          </Col>
          <Col lg="4" md="2"></Col>
        </Row>
      </Container>
    );
  }
}

export default AdminLogin;
