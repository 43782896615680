import React, { Component } from "react";
import { Container, Row, Col } from "shards-react";
import axios from "axios";

import PageTitle from "./../components/common/PageTitle";
import SmallStats from "./../components/common/SmallStats";
import config from "../utils/config";
import commonFunction from "../utils/commonFunctions";
import { Store } from "../flux";

import MostOrderedItems from "../components/Order/MostOrders";
import LeastOrderedItems from "../components/Order/LeastOrders";

class BlogOverview extends Component {
  constructor(props) {
    super(props);
    this.is_admin = Store.getAdminStatus();

    this.client_id = props.match.params.clientId || "";
    this.state = {
      leastOrderedItems: [],
      mostOrderedItems: [],
      smallStats: [
        {
          label: "Users",
          value: "0",
          percentage: "0",
          attrs: { md: "6", sm: "6" },
          datasets: [
            {
              label: "Today",
              fill: "start",
              borderWidth: 1.5,
              backgroundColor: "rgba(23,198,113,0.1)",
              borderColor: "rgb(23,198,113)",
              data: [1, 2, 3, 3, 3, 4, 4]
            }
          ]
        },
        {
          label: "Total Orders",
          value: "0",
          attrs: { md: "4", sm: "6" },
          datasets: [
            {
              label: "Today",
              fill: "start",
              borderWidth: 1.5,
              backgroundColor: "rgba(255,65,105,0.1)",
              borderColor: "rgb(255,65,105)",
              data: [1, 7, 1, 3, 1, 4, 8]
            }
          ]
        },
        {
          label: "Pending Orders",
          value: "0",
          attrs: { md: "4", sm: "6" },
          datasets: [
            {
              label: "Today",
              fill: "start",
              borderWidth: 1.5,
              backgroundColor: "rgb(0,123,255,0.1)",
              borderColor: "rgb(0,123,255)",
              data: [3, 2, 3, 2, 4, 5, 4]
            }
          ]
        },
        {
          label: "Completed Orders",
          value: "0",
          attrs: { md: "4", sm: "6" },
          datasets: [
            {
              label: "Today",
              fill: "start",
              borderWidth: 1.5,
              backgroundColor: "rgb(0,123,255,0.1)",
              borderColor: "rgb(0,123,255)",
              data: [3, 2, 3, 2, 4, 5, 4]
            }
          ]
        },
        {
          label: "Total Apps",
          value: "0",
          attrs: { md: "4", sm: "6" },
          datasets: [
            {
              label: "Today",
              fill: "start",
              borderWidth: 1.5,
              backgroundColor: "rgb(0,123,255,0.1)",
              borderColor: "rgb(0,123,255)",
              data: [3, 2, 3, 2, 4, 5, 4]
            }
          ]
        },
        // {
        //   label: "Total Courses",
        //   value: "0",
        //   attrs: { md: "4", sm: "6" },
        //   datasets: [
        //     {
        //       label: "Today",
        //       fill: "start",
        //       borderWidth: 1.5,
        //       backgroundColor: "rgb(0,123,255,0.1)",
        //       borderColor: "rgb(0,123,255)",
        //       data: [3, 2, 3, 2, 4, 5, 4]
        //     }
        //   ]
        // }
      ]
    };
  }

  fetchDashboard = () => {
    if (true) {
      const header = commonFunction.getDefaultHeader("", true);

      axios
        .post(config.api_server + "/admin/dashboard", {}, header)
        .then(resp => {
          if (resp.data && resp.data.data) {
            let dashboard = resp.data.data;
            let smallStats = this.state.smallStats;
            let leastOrderedItems = this.state.leastOrderedItems;
            let mostOrderedItems = this.state.mostOrderedItems;

            let dictState = {
              Users: dashboard.totalUserCount,
              "Total Orders": dashboard.totalOrderCount,
              "Pending Orders": dashboard.completedOrderCount,
              "Completed Orders": dashboard.pendingOrderCount,
              "Total Apps": dashboard.totalAppCount,
              "Total Restaurants": dashboard.totalRestaurantCount
            };
            smallStats = smallStats.map(state => {
              state.value = dictState[state.label];
              return state;
            });

            leastOrderedItems = resp.data.data.leastOrderedItems;
            mostOrderedItems = resp.data.data.mostOrderedItems;

            this.setState({ leastOrderedItems, mostOrderedItems, smallStats });
          }
        })
        .catch(err => {
          console.log(err);
        });
    } else {
    }
  };

  componentDidMount() {
    this.fetchDashboard();
  }

  render() {
    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle title="Dashboard" className="text-sm-left mb-3" />
        </Row>

        {/* Small Stats Blocks */}
        <Row>
          {this.state.smallStats.map((stats, idx) => (
            <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
              <SmallStats
                id={`small-stats-${idx}`}
                variation="1"
                chartData={stats.datasets}
                chartLabels={stats.chartLabels}
                label={stats.label}
                value={stats.value}
                percentage={stats.percentage}
                increase={stats.increase}
                decrease={stats.decrease}
              />
            </Col>
          ))}
        </Row>

        <Row>
          {/* Users Overview */}
          <Col lg="6" md="12" sm="12" className="mb-4">
            <MostOrderedItems list={this.state.mostOrderedItems} />
          </Col>

          <Col lg="6" md="12" sm="12" className="mb-4">
            <LeastOrderedItems list={this.state.leastOrderedItems} />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default BlogOverview;
