import moment from "moment";

const getDefaultHeader = (token = "", addToken = false, appKey = "123456789") => {
  token = localStorage.getItem("token");
  if (token != "" && addToken) {
    return {
      headers: {
        Authorization: "Bearer " + token,
        "app-key": appKey,
      },
    };
  } else {
    return {
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "app-key": "123456789",
      },
    };
  }
};

const convertToDate = (date, format = null) => {
  if (format) {
    return moment(date).format(format);
  }
  return moment(date).format("YYYY-MM-DD");
};

const convertToTime = (date) => {
  return moment(date).format("h:mma");
};

const timeCurrentDifference = (startDate) => {
  let diff = moment(startDate).diff(moment());
  let duration = moment.duration(diff);
  if (duration.asHours() < 0) {
    return -1;
  }
  return Math.floor(duration.asHours()) + moment.utc(diff).format(":mm:ss");
};

const daysDifference = (startDate) => {
  let diff = moment(startDate).diff(moment());
  let duration = moment.duration(diff);
  let days = Math.floor(duration.asDays());
  return days;
};

const methods = {
  convertToDate,
  convertToTime,
  daysDifference,
  timeCurrentDifference,
  getDefaultHeader,
};

export default methods;
