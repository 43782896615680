import React, { Component } from "react";
import { Card, CardHeader, ListGroup, ListGroupItem, Row, Col, Form, FormGroup, FormInput, Button, Tooltip } from "shards-react";

import commonFunction from "../../utils/commonFunctions";
import axios from "axios";
import config from "../../utils/config";
import { showAlert } from "../../services/alert.services";
import { Store } from "../../flux";

class UserAccountDetails extends Component {
  constructor(props) {
    super(props);
    this.is_admin = Store.getAdminStatus();
    this.title = "Account Details";
    this.state = {
      userData: {},
      change_password: {
        pin: "",
        confirm_pin: "",
      },
      top: false,
    };
  }

  changePassword = (e) => {
    const header = commonFunction.getDefaultHeader("", true);
    let api = config.api_server + "/admin/change-pin";
    console.log("API URL", api);
    if (this.is_admin) {
      api = config.api_server + "/admin/change-pin";
    }

    if (this.state.change_password.pin == this.state.change_password.confirm_pin) {
      axios
        .post(api, this.state.change_password, header)
        .then((resp) => {
          console.log(resp.data.data);
          showAlert(resp.data.message, "s");
          let change_password = this.state.change_password;
          change_password.pin = "";
          change_password.confirm_pin = "";
          this.setState({ change_password });
        })
        .catch((error) => {
          console.log("Error", error);
          /*if (error.response && error.data.message) {
            showAlert(error.data.message, "e");
          }*/
        });
    } else {
      showAlert("Both Pin Are not same.", "e");
    }

    e.preventDefault();
  };

  render() {
    return (
      <div lg="6">
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <h6 className="m-0">{"Change Password"}</h6>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <Form onSubmit={this.changePassword}>
                    <Row form>
                      {/* First Name */}
                      <Col md="6" className="form-group">
                        <label htmlFor="feFirstName">New Pin</label>
                        <FormInput
                          id="feFirstName"
                          type={"password"}
                          placeholder="New Pin"
                          value={this.state.change_password.pin}
                          onChange={(e) => {
                            let change_password = this.state.change_password;
                            change_password.pin = e.target.value;
                            this.setState({ change_password });
                          }}
                          required
                        />
                      </Col>
                      {/* Email */}
                      <Col md="6" className="form-group">
                        <label htmlFor="feEmail">Confirm Pin</label>
                        <FormInput
                          type="password"
                          id="new "
                          placeholder="Confirm Pin"
                          value={this.state.change_password.confirm_pin}
                          onChange={(e) => {
                            let change_password = this.state.change_password;
                            change_password.confirm_pin = e.target.value;
                            this.setState({ change_password });
                          }}
                          required
                        />
                      </Col>
                    </Row>
                    <Button type={"submit"} theme="accent">
                      Change Password
                    </Button>
                  </Form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </div>
    );
  }
}

export default UserAccountDetails;
