import React from "react";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider, PaginationListStandalone, PaginationTotalStandalone, SizePerPageDropdownStandalone } from "react-bootstrap-table2-paginator";
import Multiselect from "react-bootstrap-multiselect";
import { Button } from "react-bootstrap";
import { Card, CardBody, CardHeader, Row, Modal, ModalBody, ModalHeader, ModalFooter, Badge } from "shards-react";
import { Form, FormGroup, FormInput } from "shards-react";
import PageTitle from "../common/PageTitle";
import SmallStats from "../common/SmallStats";
import commonFunction from "../../utils/commonFunctions";
import config from "../../utils/config";

import { showAlert } from "../../services/alert.services";
import style from "./multi-select.css";

class ApplicationCmp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      application_data: {
        name: "",
        locations: "",
        mobile: "",
        pin: "",
      },
      list: [],
      listPattern: [],
      error: null,
      isLoaded: false,
      isModelShow: false,
      isUpdateModelShow: false,
      assign_pattern: {
        target_id: null,
        floor_pattern_id: [],
      },
    };
  }
  columns = [
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "app_key",
      text: "App Key",
    },
    {
      dataField: "locations",
      text: "Location",
    },
    {
      dataField: "mobile",
      text: "Mobile",
    },
    {
      dataField: "pin",
      text: "Pin",
    },

    {
      dataField: "createdAt",
      text: "Date",
      formatter: (cellContent, row) => {
        return <div>{commonFunction.convertToDate(row.createdAt)}</div>;
      },
    },
    {
      dataField: "option",
      text: "Option",
      formatter: (cellContent, row) => {
        return (
          <div>
            <span>Never Delete</span>
            {/*<Button
              onClick={() => {
                let assignPattern = this.state.assign_pattern;
                assignPattern.target_id = row._id;
                this.setState({ assign_pattern: assignPattern });
                this.hideUpdateModel();
              }}
              value={row._id}
              outline
              size="sm"
              theme="success"
              className="mb-2 mr-1"
            >
              Assign Pattern
            </Button>
            <Button onClick={this.handleDelete.bind("", row._id)} value={row._id} variant="outline-danger" className="mb-2 mr-1">
              <i class="far fa-times-circle"></i>
            </Button>*/}
          </div>
        );
      },
    },
  ];

  paginationOption = {
    custom: true,
    totalSize: 10,
  };

  fetchPattern = () => {
    console.log("fetch", config.api_server + "/apps");
    const header = commonFunction.getDefaultHeader("");
    console.log("Header", header);
    axios
      .get(config.api_server + "/apps", header)
      .then((resp) => {
        if (resp.data) {
          //console.log(resp.data.data);
          let patterns = resp.data.data;
          let arr = [];
          patterns.map((pattern) => {
            console.log("Patterns", patterns);
            arr.push({
              value: pattern._id,
              label: pattern.pattern_name,
            });
          });
          this.setState({ listPattern: arr });
        }
      })
      .catch((e) => {
        console.log(e);
      });
    //this.setState({ isLoaded: true });
  };

  fetchAr = () => {
    console.log("fetch", config.api_server + "/apps");
    const header = commonFunction.getDefaultHeader("");
    console.log("Header", header);
    axios
      .get(config.api_server + "/apps", header)
      .then((resp) => {
        if (resp.data) {
          //console.log(resp.data.data);
          this.setState({ list: resp.data.data, isLoaded: true });
        }
      })
      .catch((e) => {
        console.log(e);
      });
    this.setState({ isLoaded: true });
  };

  deletePattern = (id) => {
    console.log("fetch", config.api_server + "/vuforia/delete/" + id);
    const header = commonFunction.getDefaultHeader("");
    console.log("Header", header);
    axios
      .get(config.api_server + "/vuforia/delete/" + id, header)
      .then((resp) => {
        if (resp.data) {
          showAlert(resp.data.message, "s");
          this.fetchAr();
        }
      })
      .catch((e) => {
        console.log(e);
      });
    this.setState({ isLoaded: true });
  };

  componentDidMount() {
    this.fetchPattern();
    this.fetchAr();
  }

  hideModel = () => {
    this.setState({
      isModelShow: !this.state.isModelShow,
    });
  };

  hideUpdateModel = () => {
    this.setState({
      isUpdateModelShow: !this.state.isUpdateModelShow,
    });
  };

  handleDelete = (id) => {
    this.deletePattern(id);
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    let pattern = new FormData();
    const token = localStorage.getItem("token");
    console.log(this.state.application_data);

    /*for (let i = 0; i < this.state.pattern_data.images.length; i++) {
      pattern.append("images", this.state.pattern_data.images[i]);
    }

    // this.state.pattern_data.images.forEach(file => pattern.append('images',file));
    pattern.append("pattern_name", this.state.pattern_data.name);
    pattern.append("colors", this.state.pattern_data.colors);*/

    axios
      .post(config.api_server + "/apps", this.state.application_data, {
        headers: {
          Authorization: "Bearer " + token,
          "app-key": "123456789",
        },
      })
      .then((resp) => {
        if (resp.data) {
          showAlert("Application Create Successfully.", "s");
          this.fetchAr();
        }
      })
      .catch((e) => {
        this.hideModel();
        console.log(e);
      });

    this.hideModel();
  };

  handleChange = async (data) => {
    console.log("selectedData", data);
  };

  handleAssignFormSubmit = async (event) => {
    event.preventDefault();
    let form = event.target;
    console.log("form", this.refs.patterns.selectRef);

    let selected = Array.from(this.refs.patterns.selectRef)
      .filter((option) => option.selected)
      .map((option) => option.value);
    console.log("data", selected);

    const token = localStorage.getItem("token");

    let assignPattern = this.state.assign_pattern;
    assignPattern.floor_pattern_id = selected;
    this.setState({ assign_pattern: assignPattern });

    console.log("ReqBody", this.state.assign_pattern);
    const header = commonFunction.getDefaultHeader("");
    axios
      .post(config.api_server + "/vuforia/assign-content", this.state.assign_pattern, header)
      .then((resp) => {
        if (resp.data) {
          this.fetchPattern();
          this.fetchAr();
          this.hideUpdateModel();
        }
      })
      .catch((e) => {
        this.hideUpdateModel();
        console.log(e);
      });
  };

  render() {
    const { error, isLoaded } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      console.log(this.state.items);
      return (
        <>
          <Card small className="h-100">
            <CardHeader className="border-bottom">
              <Row style={{ textAlign: "right" }}>
                <div className={"col-md-12"}>
                  <Button onClick={() => this.hideModel()} className={"pull-right"} variant="outline-primary">
                    Add New Application
                  </Button>{" "}
                </div>
              </Row>
            </CardHeader>
            <CardBody className="pt-0">
              <Row className="border-bottom py-2 bg-light">
                <BootstrapTable keyField="id" keyField={"_id"} data={this.state.list} columns={this.columns} pagination={paginationFactory()} wrapperClasses="table-responsive" />
              </Row>
            </CardBody>
          </Card>
          <Modal size={"lg"} open={this.state.isModelShow} toggle={this.hideModel}>
            <ModalHeader>New Application</ModalHeader>
            <ModalBody>
              <Form id={"customer-form"} onSubmit={this.handleSubmit}>
                <FormGroup className={"row"}>
                  <label className={"col-md-2"} htmlFor="full_name">
                    Name
                  </label>
                  <FormInput
                    className={"col-md-4"}
                    placeholder="Application Name"
                    onChange={(e) => {
                      let application_data = this.state.application_data;
                      application_data.name = e.target.value;
                      this.setState({ application_data });
                    }}
                    required
                  />
                </FormGroup>
                <FormGroup className={"row"}>
                  <label className={"col-md-2"} htmlFor="full_name">
                    Locations
                  </label>
                  <FormInput
                    className={"col-md-4"}
                    placeholder="Enter Location"
                    onChange={(e) => {
                      let application_data = this.state.application_data;
                      application_data.locations = e.target.value;
                      this.setState({ application_data });
                    }}
                    required
                  />
                </FormGroup>
                <FormGroup className={"row"}>
                  <label className={"col-md-2"} htmlFor="full_name">
                    Mobile
                  </label>
                  <FormInput
                    className={"col-md-4"}
                    placeholder="Enter Mobile Number"
                    onChange={(e) => {
                      let application_data = this.state.application_data;
                      application_data.mobile = e.target.value;
                      this.setState({ application_data });
                    }}
                    required
                  />
                </FormGroup>
                <FormGroup className={"row"}>
                  <label className={"col-md-2"} htmlFor="full_name">
                    Pin
                  </label>
                  <FormInput
                    className={"col-md-4"}
                    placeholder="Generate or Create Pin"
                    value={this.state.application_data.pin}
                    onChange={(e) => {
                      let application_data = this.state.application_data;
                      application_data.name = e.target.value;
                      this.setState({ application_data });
                    }}
                    required
                  />
                  <button
                    type="button"
                    class="btn"
                    onClick={() => {
                      let randomPin = Math.floor(1000 + Math.random() * 9000).toString();
                      console.log(randomPin);
                      let application_data = this.state.application_data;
                      application_data.pin = randomPin;
                      this.setState({ application_data });
                    }}
                  >
                    <i class="fas fa-edit"></i>
                  </button>
                </FormGroup>
                {/*<FormGroup className={"row"}>
                  <label className={"col-md-2"} htmlFor="email">
                    Image
                  </label>
                  <FormInput
                    className={"col-md-4"}
                    type={"file"}
                    onChange={(e) => {
                      console.log("File==>", e.target.files);
                      let application_data = this.state.application_data;
                      application_data.images = e.target.files;
                      this.setState({ application_data });
                    }}
                    multiple
                    required
                  />
                  </FormGroup>*/}
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button form={"customer-form"} type={"submit"}>
                Save
              </Button>
              <Button className={"btn-secondary"} onClick={this.hideModel}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
          <Modal size={"lg"} open={this.state.isUpdateModelShow} toggle={this.hideUpdateModel}>
            <ModalHeader>Assign Patterns</ModalHeader>
            <ModalBody>
              <Form id={"customer-form"} onSubmit={this.handleAssignFormSubmit}>
                <FormGroup className={"row"}>
                  <label className={"col-md-2"} htmlFor="app_name">
                    Select Content
                  </label>
                  <Multiselect data={this.state.listPattern} class={"col-md-4 form-control"} ref="patterns" multiple id="patterns" name="patterns" />
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button form={"customer-form"} type={"submit"}>
                Save
              </Button>
              <Button className={"btn-secondary"} onClick={this.hideUpdateModel}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </>
      );
    }
  }
}

export default ApplicationCmp;
