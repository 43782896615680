export const adminSidebarItems = () => {
  return [
    {
      title: "Home",
      to: "/dashboard",
      htmlBefore: '<i class="material-icons">edit</i>',
      htmlAfter: "",
    },
    {
      title: "Customers123",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/customer-list",
    },
    {
      title: "Booking History",
      htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/bookingHistory",
    },
    {
      title: "Transactions",
      htmlBefore: '<i class="material-icons">receipt</i>',
      to: "/transactions",
    },
    {
      title: "Profile",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/profile",
    },
    // {
    //   title: "Logout",
    //   htmlBefore: '<i class="material-icons">view_module</i>',
    //   to: "/customer",
    // },
    /*{
      title: "Forms & Components",
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/components-overview"
    },
    {
      title: "Tables",
      htmlBefore: '<i class="material-icons">table_chart</i>',
      to: "/tables"
    },
    {
      title: "User Profile",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/user-profile-lite"
    },
    {
      title: "Errors",
      htmlBefore: '<i class="material-icons">error</i>',
      to: "/errors"
    }*/
  ];
};

export const customerSidebarItems = () => {
  return [
    {
      title: "Home",
      to: "/dashboard",
      htmlBefore: '<i class="material-icons">edit</i>',
      htmlAfter: "",
    },
    {
      title: "Apps",
      to: "/apps",
      htmlBefore: '<i class="material-icons">face</i>',
      htmlAfter: "",
    },
    {
      title: "Customers",
      to: "/users",
      htmlBefore: '<i class="material-icons">person</i>',
      htmlAfter: "",
    },
    {
      title: "Admin Profile",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/profile",
    },
    // {
    //   title: "Logout",
    //   htmlBefore: '<i class="material-icons">view_module</i>',
    //   to: "/",
    // },
  ];
};
