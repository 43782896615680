import * as actions from "./actions";

export default function reducer(state = {}, action) {
  switch (action.type) {
    case actions.IS_ADMIN_LOGIN:
      return {
        ...state,
        isAdminLogin: true,
        isCustomerLogin: false,
        adminId: 1,
      };
    case actions.IS_CUSTOMER_LOGIN: {
      return {
        ...state,
        isAdminLogin: false,
        isCustomerLogin: true,
        customerId: 1,
      };
    }
  }
}
