import React from "react";
import { Card, CardBody, CardHeader, Row, Modal, ModalBody, ModalHeader, ModalFooter } from "shards-react";
import { Form, FormGroup, FormInput } from "shards-react";
import { Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider, PaginationListStandalone, PaginationTotalStandalone, SizePerPageDropdownStandalone } from "react-bootstrap-table2-paginator";
import "./style.css";

export default function index(props) {
  console.log("LeastOrderedProps => ", props);

  let columns = [
    {
      dataField: "first_name ",
      text: "Name",
      formatter: (cellContent, row) => {
        return row.name;
      },
    },
    {
      dataField: "price",
      text: "Price",
      formatter: (cellContent, row) => {
        return "$" + row.price;
      },
    },
    {
      dataField: "price",
      text: "Orders",
      formatter: (cellContent, row) => {
        return row.total_ordered;
      },
    },
  ];

  return (
    <>
      <Card small className="h-100">
        <CardHeader className="border-bottom">
          <Row style={{ textAlign: "" }}>
            <div className={"col-md-6"}>
              <h4>Least Ordered Items</h4>
            </div>
          </Row>
        </CardHeader>
        <CardBody className="pt-0">
          <Row className="border-bottom py-2 bg-light">
            <BootstrapTable keyField="id" keyField={"_id"} data={props.list} columns={columns} wrapperClasses="table-responsive" />
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
