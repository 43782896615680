import { EventEmitter } from "events";

import Dispatcher from "./dispatcher";
import Constants from "./constants";
import {
  adminSidebarItems,
  customerSidebarItems,
} from "../data/sidebar-nav-items";

let _store = {
  menuVisible: false,
  navItems: adminSidebarItems(),
  customerNavItems: customerSidebarItems(),
  is_admin: false
};

class Store extends EventEmitter {
  constructor() {
    super();
    this.registerToActions = this.registerToActions.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.initialPersistant();
    Dispatcher.register(this.registerToActions.bind(this));
  }

  initialPersistant = () => {
    let key = sessionStorage.getItem('state');
    if(key){
      try{
        _store = JSON.parse(key);
      } catch (e) {
        console.log(e);
        sessionStorage.setItem('state', JSON.stringify(_store));
      }
    } else {
      sessionStorage.setItem('state', JSON.stringify(_store));
    }
  };

  setPersistant = () => {
    sessionStorage.setItem('state', JSON.stringify(_store));
  };

  registerToActions({ actionType, payload }) {
    switch (actionType) {
      case Constants.TOGGLE_SIDEBAR:
        this.toggleSidebar();
        break;
      case Constants.SET_ADMIN:
        this.setAdminStatus(payload);
        break;
      default:
    }
  }

  toggleSidebar() {
    _store.menuVisible = !_store.menuVisible;
    this.setPersistant();
    this.emit(Constants.CHANGE);
  }

  getMenuState() {
    return _store.menuVisible;
  }

  getSidebarItems() {
    return _store.navItems;
  }

  getCustomerSidebarItems() {
    return _store.customerNavItems;
  }

  getAdminStatus(){
    return _store.is_admin;
  }

  addChangeListener(callback) {
    this.on(Constants.CHANGE, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(Constants.CHANGE, callback);
  }

  setAdminStatus = (status) => {
    _store.is_admin = status;
    this.setPersistant();
    this.emit(Constants.SET_ADMIN);
  }
}

export default new Store();
