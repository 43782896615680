import React, { Component } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Row
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import FormList from "../components/form/list";
import ApplicationCmp from "../components/Applications";

class ApplicationView extends Component {
  render() {
    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Applications"
            subtitle="List of"
            className="text-sm-left"
          />
        </Row>

        {/* First Row of Posts */}
        <Row>
          {/* Users Overview */}
          <Col lg="12" md="12" sm="12" className="mb-4">
            <ApplicationCmp />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ApplicationView;
