import React, { useState, useEffect } from "react";
import commonFunction from "../../utils/commonFunctions";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  Button,
  ListGroup,
  ListGroupItem,
  Progress,
} from "shards-react";

const UserDetails = (props) => {
  console.log("0");

  const [User, setUser] = useState(0);

  useEffect(() => {
    console.log("1");
    console.log(props.userInfo);

    setUser(props.userInfo);
    console.log("2");
  });

  return (
    <Card small className="mb-4 pt-3">
      <CardHeader className="border-bottom text-center">
        <div className="mb-3 mx-auto">
          <img
            className="rounded-circle"
            src={User.profile_image}
            alt=""
            width="110"
          />
        </div>
        <h4 className="mb-0">{User.first_name + " " + User.last_name}</h4>
        <span className="text-muted d-block mb-2">{User.email}</span>
        <Button pill outline size="sm" className="mb-2">
          <i className="material-icons mr-1">person_add</i>{" "}
          {User.stripe_customer_id}
        </Button>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="px-4">
          <div className="progress-wrapper">
            <strong className="text-muted d-block mb-2">Date Of Birth</strong>
            <Progress className="progress-sm" value={"Address"}>
              <span className="progress-value">
                {commonFunction.convertToDate(User.date_of_birth)}
              </span>
            </Progress>
          </div>
        </ListGroupItem>
        <ListGroupItem className="px-4">
          <div className="progress-wrapper">
            <strong className="text-muted d-block mb-2">Mobile</strong>
            <Progress className="progress-sm" value={"Mobile"}>
              <span className="progress-value">{User.mobile_number}</span>
            </Progress>
          </div>
        </ListGroupItem>
        {/** <ListGroupItem className="p-4">
        <strong className="text-muted d-block mb-2">
          Date Of Birth
        </strong>
        <span>{User.date_of_birth}</span>
      </ListGroupItem> */}
      </ListGroup>
    </Card>
  );
};

UserDetails.propTypes = {
  /**
   * The user details object.
   */
  userDetails: PropTypes.object,
};

UserDetails.defaultProps = {
  userDetails: {
    name: "Sierra Brooks",
    avatar: require("./../../images/avatars/0.jpg"),
    jobTitle: "Project Manager",
    performanceReportTitle: "Workload",
    performanceReportValue: 74,
    metaTitle: "Description",
    metaValue:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio eaque, quidem, commodi soluta qui quae minima obcaecati quod dolorum sint alias, possimus illum assumenda eligendi cumque?",
  },
};

export default UserDetails;
