import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Card, CardBody, Form, FormInput } from "shards-react";
import { withRouter } from "react-router-dom";

import { login } from "../../services/auth.services";
import * as actions from "../../redux/actions";
import store from "../../redux/stores";

import { showAlert } from "../../services/alert.services";

class Login extends Component {
  constructor(props) {
    super(props);
    this.is_admin = props.is_admin;
    this.state = {
      login: {
        mobile: "",
        pin: "",
        user_type: "master_admin",
      },
    };
  }

  login = async (event) => {
    event.preventDefault();
    console.log("LoginApiCalled");
    let flag = true;
    for (let key of Object.keys(this.state.login)) {
      if (this.state.login[key] === "") {
        flag = false;
        break;
      }
    }
    if (flag) {
      let loginResponse = await login(this.state.login, this.is_admin);
      console.log("loginResponse", loginResponse);

      if (loginResponse && !loginResponse.success && loginResponse.data) {
        localStorage.setItem("token", loginResponse.data.data.token);
        showAlert(loginResponse.data.message, "s");
        this.props.history.push("/dashboard");
      } else {
        if (loginResponse.response && loginResponse.response.data) {
          showAlert(loginResponse.response.data.message, "e");
        }
        console.log("Login Error", loginResponse.response);
      }
    }
  };

  componentDidMount() {
    localStorage.clear();
    console.log("store", store);
    store.dispatch({
      type: actions.IS_ADMIN_LOGIN,
      adminId: 10,
      isAdminLogin: true,
    });
  }

  render() {
    return (
      <Card small className="mb-3">
        <CardBody>
          <Form className="add-new-post" onSubmit={this.login}>
            <FormInput
              size="lg"
              onChange={(e) => {
                if (e.target.value) {
                  let login = this.state.login;
                  login.mobile = e.target.value;
                  this.setState({ login });
                }
              }}
              required
              className="mb-3"
              placeholder="Enter your mobile"
            />
            <FormInput
              size="lg"
              className="mb-3"
              onChange={(e) => {
                if (e.target.value) {
                  let login = this.state.login;
                  login.pin = e.target.value;
                  this.setState({ login });
                }
              }}
              required
              placeholder="Enter your pin"
              type={"password"}
            />
            <Button size="md" block variant="outline-primary" type={"submit"}>
              Login
            </Button>
          </Form>
        </CardBody>
      </Card>
    );
  }
}

export default withRouter(Login);
