import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { Nav } from "shards-react";

import Notifications from "./Notifications";
import UserActions from "./UserActions";

export default () => (
  <Nav navbar className=" flex-row">
    {/*<UserActions />*/}
    <Link to={"/"}>
      <p
        style={{
          padding: "10px",
          paddingTop: "20px",
          color: "white",
          cursor: "pointer",
        }}
      >
        Logout
      </p>
    </Link>
  </Nav>
);
