export default {
  //api_server: "http://localhost:3011/api", //Local Server
  //server_url: "http://172.105.35.50:4400/api", //Frontend Server
  //server_url: "http://172.105.35.50:3000", //Frontend Server
  //api_server: "http://172.105.35.50:4400/api", //Development Server

  // Live Server credential
  server_url: "https://api.2yourcart.com/api", //Frontend Server
  api_server: "https://api.2yourcart.com/api", //API URL
};
